import React, { useState, useEffect } from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import Helpers from "../app/helpers"
import Layout from "../app/layouts/default"
import Seo from "../app/services/seo"

import Button from "../app/atoms/button";
import * as Megacontent from "../app/helpers/megacontent";

import RelatedProducts from "../app/modules/related-products"
import StickyMenu from "../app/components/sticky-menu"

import { Waypoint } from 'react-waypoint';
import ReevooEmbeddable from "../app/components/reevoo/reevoo-embeddable";

import { default as Accordion } from "../app/components/custom-accordion";
import AccordionDetailsDescriptionList from "../app/components/accordion-details-description-list";

import ProductDetailHero from "../app/modules/product-detail-hero";
import ProductDetailMeasures from "../app/components/product-detail-measures";
import StickyButtons from "../app/components/sticky-buttons";

import * as changeCase from "change-case";

import ProductDetailConnectivity from "../app/components/product-detail-connectivity";
import ProductDetailDocuments from "../app/components/product-detail-documents";

import CompareOverlay from "../app/components/compare-overlay";

const { dynamic, gallery, hero, triplet, listingIcons, listingImages, infoModule, editorialPush } = Megacontent.data;


const ProductPage = (props) => {

    const data = useStaticQuery(graphql`
    {
      babelProdotto(key_prod: {eq: "31011204"}) {
        attributes {
          id
          category {
            id
            nameCategory
            ordCategory
            parentCategory
          }
          fieldName
          fieldType
          fieldValue
        }
        lineId
        key_prod
        id
        familiesIds {
          title
          id
          triples {
            id
            image
            linkcall
            linkimg
            market_exception
            text
            titimg
            title
            video
          }
        }
        brand_id
        brandName
        productName
        primaryimage {
          imageType
          imageUrl
        }
        triples {
          id
          image
          keySellingPoint
          linkcall
          linkimg
          market_exception
          text
          titimg
          title
          video
        }
        connectivity
      }
      allBabelProdotto(
        filter: {familiesIds: {}, primaryimage: {elemMatch: {imageType: {}}}, lineId: {eq: 777}}
      ) {
        edges {
          node {
            key_prod
            attributes {
              id
              category {
                id
                nameCategory
                ordCategory
                parentCategory
              }
              fieldName
              fieldType
              fieldValue
            }
            lineId
            id
            dimensions {
              a
              l
              p
            }
            otherImages {
              imageUrl
              imageType
            }
            familiesIds {
              title
              id
              triples {
                id
                image
                linkcall
                linkimg
                market_exception
                text
                titimg
                title
                video
              }
            }
            brand_id
            brandName
            productName
            primaryimage {
              imageType
              imageUrl
            }
            internal {
              description
              ignoreType
              mediaType
              content
            }
            triples {
              id
              image
              keySellingPoint
              linkcall
              linkimg
              market_exception
              text
              titimg
              title
              video
            }
            connectivity
          }
        }
      }
    }
  `)


    const keyProds = [
        {
            "node": {
                "key_prod": "31011177"
            }
        },
        {
            "node": {
                "key_prod": "31011204"
            }
        },
        {
            "node": {
                "key_prod": "31011472"
            }
        },
        {
            "node": {
                "key_prod": "31010954"
            }
        }
    ]


    let products = data.allBabelProdotto.edges;
    products = Helpers.productsDataManipulation(products, false);

    const rawAttributes = data.allBabelProdotto.edges.map(({ node: { attributes } }) => attributes);

    const attributes = rawAttributes
        .slice(0, 4)
        .map(product => product
            .filter(property => property.category.id === 96)
            .map(property => ({
                property: property.fieldName,
                propertyId: property.id,
                value: property.fieldValue
            })));

    const categories = attributes.reduce((end, current) => {
        const newValues = current.reduce((end, current) => {
            const label = current.property;
            if (!end.find(item => item?.id === current.propertyId)) {
                end.push({
                    label,
                    id: current.propertyId
                });
            }
            return end;
        }, [])
            .filter(item => !end.find(i => i.id === item.id));
        return [...end, ...newValues];
    }, []);


    const [currentSubmenuItem, setCurrentSubmenuItem] = useState(null);

    const handleWaypointEnter = (event, idx) => {
        setCurrentSubmenuItem(idx);
        // console.log('currentStickyMenuIndex', idx, event);
    };

    const currentProduct = data.allBabelProdotto.edges.find(edge => edge.node.key_prod === '31011204').node;

    const { key_prod, productNameComposed, dimensions } = currentProduct;

    const waypoints = [
        {
            name: 'Panoramica',
            children: <>
                <Megacontent.Triplet
                    introTitle={"Triplet - Media"}
                    introText={'Nullam id dolor id nibh ultricies vehicula ut id elit. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec ullamcorper nulla non metus auctor fringilla.'}
                    introLabel={'Scopri di più'}
                    introLink={'#'}
                    items={triplet.items} />
            </>
        },
        {
            name: 'Scheda di dettaglio',
            children: <>
                <section className="pdp-details" id="pdp-details">
                    <div className="container">
                        <h2>
                            Scheda di dettaglio
                        </h2>
                        <Accordion title="Caratteristiche principali">
                            <AccordionDetailsDescriptionList categories={categories} product={attributes[0]} />
                        </Accordion>
                        <Accordion title="Caratteristiche tecniche">
                            <AccordionDetailsDescriptionList categories={categories} product={attributes[0]} />
                        </Accordion>
                        <Accordion title="Programmi e funzioni">
                            <AccordionDetailsDescriptionList categories={categories} product={attributes[0]} />
                        </Accordion>
                        <Accordion title="Estetica">
                            <AccordionDetailsDescriptionList categories={categories} product={attributes[0]} />
                        </Accordion>
                        <Accordion title="Performance">
                            <AccordionDetailsDescriptionList categories={categories} product={attributes[0]} />
                        </Accordion>
                        <Accordion title="Dimensioni">
                            <AccordionDetailsDescriptionList categories={categories} product={attributes[0]} />
                        </Accordion>
                    </div>
                </section>
            </>
        },
        {
            name: null, //sometimes you may not want to create a waypoint, and just add a section
            addWaypoint: false,
            children: <>
                <Megacontent.Push
                    callToAction={{
                        label: 'Scopri di più',
                        link: '/',
                        target: '_self'
                    }}
                    color="blue"
                    image="https://www.candy-home.com/adapt-image/270654/Connectivity.png?w=1600&h=660&q=60&fm=webp&version=1.0&t=1626167419260"
                    introLabel=""
                    introLink=""
                    introText=""
                    pretitle=""
                    text="Praesent commodo cursus magna, vel scelerisque nisl consectetur et."
                    title="10 anni di garanzia con Cany"
                />
            </>
        },
        {
            name: 'Prodotti correlati',
            children: <>
                <section className="pdp-related-products">
                    <h2>
                        Potrebbe interessarti anche
                    </h2>
                    <RelatedProducts
                        lightVersion
                        products={products.slice(0, 4)}
                    />
                </section>
            </>
        },
        {
            name: 'Recensioni',
            children: <>
                <section className="pdp-reviews">
                    <h2>
                        Recensioni
                    </h2>
                    <ReevooEmbeddable productId={key_prod} />
                </section>
            </>
        },

    ]

    const breadcrumbs = [
        {
            link: '/',
            label: 'Home'
        },
        {
            link: '/lavatrici',
            label: 'Lavatrici a carica frontale'
        },
        {
            label: productNameComposed
        },
    ];

    const tableValues = [
        { label: 'Partenza ritardata', value: 'Sì (fino a 24h)' },
        { label: 'Partenza ritardata', value: 'Sì (fino a 24h)' },
        { label: 'Partenza ritardata', value: 'Sì (fino a 24h)' },
        { label: 'Partenza ritardata', value: 'Sì (fino a 24h)' },
        { label: 'Partenza ritardata', value: 'Sì (fino a 24h)' },
        { label: 'Partenza ritardata', value: 'Sì (fino a 24h)' }
    ];

    const productDetailConnectivityValues = {
        description: 'Registrati e scopri l\'app anche in versione demo',
        mobileApp: {
            imageUrl: '/candy-app.png',
            title: 'Candy simply-Fi',
            rating: 4.5,
            description: '4.6 • 145K valutazioni'
        },
        storeBadges: {
            googlePlay: 'http://example.com',
            appGallery: 'http://example.com',
            appStore: 'http://example.com'
        },
        buttonLabel: 'Scopri di più sulla connettività',
        buttonOptions: {
            className: 'arrow _15',
            color: 'blue',
            href: 'https://examle.com',
            type: 'arrow'
        }
    };

    const productDetailDocumentsData = [
        {
            link: '',
            iconType: 'symbols-doc',
            label: 'Scheda prodotto'
        }, {
            link: '',
            iconType: 'symbols-pdf',
            label: 'Libretto istruzioni'
        }, {
            link: '',
            iconType: 'symbols-pdf',
            label: 'Scheda prodotto EU'
        }, {
            link: '',
            iconType: 'symbols-pdf',
            label: 'Etichetta energetica EU'
        }, {
            link: '',
            iconType: 'symbols-pdf',
            label: 'Dichiarazione di conformità CE'
        }
    ];

    const shareUrl = 'https://candy.com';

    const overlayItems = products.slice(0, 4).map((
        {
            node: {
                familiesIds,
                primaryimage,
                productName,
                productNameVerbose
            }
        }) => ({
            image: primaryimage.find(item => item?.imageType === '100')?.imageUrl,
            family: familiesIds?.map(item => item.title).join(', ') || '',
            name: productNameVerbose,
            model: productName
        }));

    return (
        <Layout
            headerActiveItem={1}
            mainClassName="pdp-page"
            reevooEmbeddable={null} // we do NOT inject the value like in the plp, because here we need the reviews to be in the waypoint loop
            breadcrumbs={breadcrumbs}

            overlay
            overlayComponents={[
                { component: CompareOverlay, props: { items: overlayItems } }
            ]}
        >
            <Seo title={productNameComposed} keywords={[`gatsby`, `application`, `react`]} />
            <ProductDetailHero product={currentProduct} inCompare={true} />
            <ProductDetailConnectivity {...productDetailConnectivityValues} />
            <ProductDetailMeasures
                tableValues={tableValues}
                dimensions={dimensions}
                dimensionsLabel="DIMENSIONI PRODOTTO (mm)"
            />
            <ProductDetailDocuments items={productDetailDocumentsData} />
            <StickyButtons
                product={currentProduct}
                downloads
                compare={{
                    current: true
                }}
                socials={[
                    {
                        name: 'facebook',
                        link: `http://www.facebook.com/sharer.php?u=${shareUrl}`,

                    },
                    {
                        name: 'twitter',
                        link: `http://twitter.com/share?url=${shareUrl}`,

                    },
                    {
                        name: 'whatsapp',
                        link: `https://api.whatsapp.com/send?text=${productNameComposed} ${shareUrl}`,

                    },
                    {
                        name: 'mail',
                        link: `mailto:?subject=${productNameComposed}&body=${shareUrl}`,

                    },
                    {
                        name: 'link',
                        link: shareUrl,
                    },
                ]}
                notifications={[
                    {
                        label: '10 anni di garanzia Candy',
                        text: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie.</p>',
                        cta: {
                            label: 'Richiedi la garanzia Candy',
                            to: 'http://example.com',
                            target: '_blank',
                        }
                    }
                ]}
            />
            <StickyMenu
                currentItem={currentSubmenuItem}
                waypoints={waypoints}
                onActiveChange={idx => {
                    setTimeout(() => {
                        //console.log(222, idx);
                        handleWaypointEnter(null, idx)
                    }, 250);
                }}
            />
            {
                waypoints.map((waypoint, idx) => (
                    waypoint.addWaypoint != false ?
                        <Waypoint
                            key={idx}
                            onEnter={(event) => handleWaypointEnter(event, idx)}
                            topOffset='200px'
                            bottomOffset='200px'
                        >
                            <section
                                className="waypoint"
                                >
                                <div
                                    className="waypoint--controller"
                                    id={changeCase.paramCase(waypoint.name)}
                                >
                                </div>
                                {waypoint.children}
                            </section>
                        </Waypoint>

                        :
                        <section
                            className="waypoint"
                        >
                            {waypoint.children}
                        </section>

                ))
            }
        </Layout>
    )
};

export default ProductPage;
